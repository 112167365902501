import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class FooterBlock extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container className="app-footer">
          <Row>
            <Col>Copyright 2020 by Maciej Łabędzki </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default FooterBlock;
