import React from "react";

import Badge from "react-bootstrap/Badge";

import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";

import dataStorage from "../../../data/storage";

import Modal from "../../features/modal/modal";

import LazyLoad from "react-lazyload";

class CardBlock extends React.Component {
  render() {
    return (
      <Card>
        <div className="card-img">
          <Card.Img
            variant="top"
            alt={this.props.data["title"]}
            onClick={this.props.handleModal}
            src={this.props.data["img"]}
          />
        </div>
        <Card.Body>
          <Card.Title>{this.props.data["title"]}</Card.Title>
          <hr></hr>
          <Card.Subtitle className="mb-2 text-muted">
            id: {this.props.data["id"]}
          </Card.Subtitle>
          <hr></hr>
          <Card.Text className="text-wrapper">
            {this.props.data["text"]}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Link:</small>
          <span className="app-link">
            {this.props.data["links"]["preview"] !== null && (
              <a
                href={this.props.data["links"]["preview"]}
                rel="noopener noreferrer"
                target="_blank"
                className="card-footer__link"
              >
                {this.props.data["title"]}
              </a>
            )}
            {this.props.data["links"]["preview"] === null && <>no link</>}
          </span>
        </Card.Footer>
        <Card.Footer>
          <small className="text-muted">Tag</small>
          <div>
            {this.props.data["tag"].map((item) => {
              return (
                <Badge pill variant={item} key={"id_badge" + item}>
                  {item}
                </Badge>
              );
            })}
          </div>
        </Card.Footer>

        {/* <Card.Footer>
          <small className="text-muted">Link source:</small>
          <span className="app-link">
            {this.props.data["links"]["source"] !== null && (
              <a
                href={this.props.data["links"]["source"]}
                rel="noopener noreferrer"
                target="_blank"
              >
                {this.props.data["links"]["source"]}
              </a>
            )}
            {this.props.data["links"]["source"] === null && <>no link</>}
          </span>
        </Card.Footer> */}
      </Card>
    );
  }
}

const BuildCardDecks = (props) => {
  var a = dataStorage;
  var aStatusTrue = [];
  var b = [];
  var c = [];
  var sttt = [];

  // eslint-disable-next-line
  a.map((item, index) => {
    if (item["status"] !== false) {
      aStatusTrue.push(item);
    }
  });

  // eslint-disable-next-line
  aStatusTrue.map((item, index) => {
    if (c.length < 3) {
      c.push(item);
    } else {
      b[b.length] = c;
      c = [];
      c.push(item);
    }
    if (aStatusTrue % 3 !== 0) {
      if (index === aStatusTrue.length - 1) {
        b[b.length] = c;
      }
    }
  });

  // eslint-disable-next-line
  b.map((item, index) => {
    sttt.push(
      <LazyLoad height={500} offset={100} key={"id_LazyLoad" + index}>
        <div
          className="app-container bounceInLeft animated delay-02s "
          key={"id_cardDecks" + index}
        >
          <CardDeck key={"id_cardDeck" + item}>
            {item.map((a, number) => {
              return (
                <CardBlock
                  data={a}
                  key={"id_cardBlock" + number}
                  handleModal={props.handleModal}
                />
              );
            })}
          </CardDeck>
        </div>
      </LazyLoad>
    );
  });

  return sttt;
};

class CardDeckBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: "",
      img: null,
      text: "Modal Title",
    };
  }
  handleModal = (e) => {
    console.log("sss", this.state, e.target);
    this.setState({
      isModal: true,
      img: e.target.getAttribute("src"),
      text: e.target.getAttribute("alt"),
    });
  };
  render() {
    return (
      <React.Fragment>
        <div id="section-gallery">
          <BuildCardDecks handleModal={this.handleModal} />
          <Modal
            isVisible={this.state.isModal}
            title={this.state.text}
            content={
              <p>
                <img src={this.state.img} alt="portfolio images" />
              </p>
            }
            footer={
              <button
                onClick={() => {
                  alert("Nothing to do yet.");
                }}
              >
                Cancel
              </button>
            }
            onClose={() => this.setState({ isModal: false })}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CardDeckBlock;
