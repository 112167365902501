import img_1 from "../assets/img/tagIcon/code-git.png";
import img_2 from "../assets/img/tagIcon/code-grunt.png";
import img_3 from "../assets/img/tagIcon/code-vsc.png";
import img_4 from "../assets/img/tagIcon/css-bem.png";
import img_5 from "../assets/img/tagIcon/css-bootstrap.png";
import img_6 from "../assets/img/tagIcon/css-sass.png";
import img_7 from "../assets/img/tagIcon/design-illustrator.png";
import img_8 from "../assets/img/tagIcon/design-ps.png";
import img_9 from "../assets/img/tagIcon/lang-html.png";
import img_10 from "../assets/img/tagIcon/lang-jquery.png";
import img_11 from "../assets/img/tagIcon/lang-js.png";
import img_12 from "../assets/img/tagIcon/lang-node.png";
import img_13 from "../assets/img/tagIcon/lang-react.png";

const TechnologyList = [
  { name: "react", img: img_13 },
  { name: "js", img: img_11 },
  { name: "node", img: img_12 },
  { name: "sass", img: img_6 },
  { name: "html", img: img_9 },
  { name: "jquery", img: img_10 },
  { name: "git", img: img_1 },
  { name: "grunt", img: img_2 },
  { name: "vsc", img: img_3 },
  { name: "bem", img: img_4 },
  { name: "bootstrap", img: img_5 },
  { name: "ps", img: img_8 },
  { name: "illustrator", img: img_7 },
];

export default TechnologyList;
