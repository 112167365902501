import img0 from "../assets/img/0/img.png";
import img109 from "../assets/img/109/img.png";
import img108 from "../assets/img/108/img.png";
import img99 from "../assets/img/99/img.png";
import img106 from "../assets/img/106/img.png";
import img1 from "../assets/img/1/img.png";
import img2 from "../assets/img/2/img.png";
import img103 from "../assets/img/103/img.png";
import img102 from "../assets/img/102/img.png";
import img98 from "../assets/img/98/img.png";
import img110 from "../assets/img/110/img.png";
import img97 from "../assets/img/97/img.png";
import img91 from "../assets/img/91/img.png";
import img74 from "../assets/img/74/img.png";
import img3 from "../assets/img/3/img.png";
import img5 from "../assets/img/5/img.png";
import img6 from "../assets/img/6/img.png";
import img8 from "../assets/img/8/img.png";
import img36 from "../assets/img/36/img.png";
import img72 from "../assets/img/72/img.png";
import img73 from "../assets/img/73/img.png";
import img76 from "../assets/img/76/img.png";
import img77 from "../assets/img/77/img.png";
import img78 from "../assets/img/78/img.png";
import img79 from "../assets/img/79/img.png";
import img80 from "../assets/img/80/img.png";
import img81 from "../assets/img/81/img.png";
import img82 from "../assets/img/82/img.png";
import img83 from "../assets/img/83/img.png";
import img84 from "../assets/img/84/img.png";
import img85 from "../assets/img/85/img.png";
import img86 from "../assets/img/86/img.png";
import img87 from "../assets/img/87/img.png";
import img88 from "../assets/img/88/img.png";
import img89 from "../assets/img/89/img.png";
import img90 from "../assets/img/90/img.png";
import img92 from "../assets/img/92/img.png";
import img93 from "../assets/img/93/img.png";
import img94 from "../assets/img/94/img.png";
import img95 from "../assets/img/95/img.png";
import img96 from "../assets/img/96/img.png";
import img100 from "../assets/img/100/img.png";
import img101 from "../assets/img/101/img.png";
import img111 from "../assets/img/111/img.png";

var dataStorage = [
  {	id:	0	,	minified:"	true	",	title:"	Website: Movie Database	",	img:	img0	,	text:"	Website with search engine that provides movies information from IMDB.	",	tag:[	"site","javascript","react","playground","sass","ajax", "JSON","react-route"	],	links:	{	preview:"	http://maciejlabedzki.pl/moviedb/#/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	109	,	minified:"	true	",	title:"	Website: Allin1soft	",	img:	img109	,	text:"	Website for company Allin1soft.	",	tag:[	"code","js","site",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/109%20-%20code%20-%20js%20-%20site%20-%20allin1soft/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	108	,	minified:"	true	",	title:"	Website: CryptoCore	",	img:	img108	,	text:"	Website for company Cryptocore.	",	tag:[	"code","js","site",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/108%20-%20code%20-%20js%20-%20site%20-%20cryptocore/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	99	,	minified:"	true	",	title:"	Website: Betlotte	",	img:	img99	,	text:"	Website for company Betlotte.	",	tag:[	"code","js","site"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/99%20-%20code%20-%20js%20-%20site%20-%20betlotte/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	106	,	minified:"	true	",	title:"	Website: Accessbet for mobile 	",	img:	img106	,	text:"	Mobile website for company Accessbet.	",	tag:[	"code","js","react","mobile",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/106%20-%20code%20-%20mobile%20-%20page%20-%20home/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	5	,	minified:"	false	",	title:"	Widget: Mobile live match 	",	img:	img5	,	text:"	Widget for company WebLogiq.	",	tag:[	"widget","code","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/5%20-%20code%20-%20js%20-%20widget%20live%20sport/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	78	,	minified:"	true	",	title:"	Animation: Mini Jackpot	",	img:	img78	,	text:"	Animation built for company Weblogiq. Shows mini win result.	",	tag:[	"animation","css","js","code"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/78%20-%20code%20-%20js%20-%20animation%20-%20jackpot%20-%20mini/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	79	,	minified:"	true	",	title:"	Animation: Mega Jackpot	",	img:	img79	,	text:"	Animation built for company Weblogiq. Shows mega win result.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/79%20-%20code%20-%20js%20-%20animation%20-%20jackpot%20-%20mega/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	80	,	minified:"	true	",	title:"	Animation: Major Jackpot	",	img:	img80	,	text:"	Animation built for company Weblogiq. Shows major win result.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/80%20-%20code%20-%20js%20-%20animation%20-%20jackpot%20-%20major/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	81	,	minified:"	true	",	title:"	Animation: Minor Jackpot	",	img:	img81	,	text:"	Animation built for company Weblogiq. Shows minor win result.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/81%20-%20code%20-%20js%20-%20animation%20-%20jackpot%20-%20minor/code/	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	82	,	minified:"	true	",	title:"	Animation: Jackpot Landing	",	img:	img82	,	text:"	Animation built for company Weblogiq. Shows jackpot win result landing page.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/82/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	83	,	minified:"	true	",	title:"	Animation: Mega Jackpot Step 2	",	img:	img83	,	text:"	Animation built for company Weblogiq. Shows jackpot mega win result for step 2.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/83/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	85	,	minified:"	true	",	title:"	Animation: Major Jackpot Step 2	",	img:	img85	,	text:"	Animation built for company Weblogiq. Shows jackpot major win result for step 2.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/85/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	86	,	minified:"	true	",	title:"	Animation: Minor Jackpot Step 2	",	img:	img86	,	text:"	Animation built for company Weblogiq. Shows jackpot minor win result for step 2.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/86/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	87	,	minified:"	true	",	title:"	Animation: Minor Jackpot Step 3	",	img:	img87	,	text:"	Animation built for company Weblogiq. Shows jackpot minor win result for step 3.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/87/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	88	,	minified:"	true	",	title:"	Animation: Mini Jackpot Step 3	",	img:	img88	,	text:"	Animation built for company Weblogiq. Shows jackpot mini win result for step 3.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/88/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	89	,	minified:"	true	",	title:"	Animation: Mega Jackpot Step 3	",	img:	img89	,	text:"	Animation built for company Weblogiq. Shows jackpot mega win result for step 3.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/89/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	90	,	minified:"	true	",	title:"	Animation: Major Jackpot Step 3	",	img:	img90	,	text:"	Animation built for company Weblogiq. Shows jackpot major win result for step 3.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/90/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	84	,	minified:"	true	",	title:"	Animation: Mini Jackpot Step 2	",	img:	img84	,	text:"	Animation built for  company Weblogiq. Shows jackpot mini win result for step 2.	",	tag:[	"animation","css","js","code","work"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/84/code/jpwin.html	",	source:"		"},	status:	true	,	order:	1	},
  {	id:	110	,	minified:"	true	",	title:"	Tool: Translator helper	",	img:	img110	,	text:"	Tool for translators for company Allin1soft.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/110%20-%20code%20-%20js%20-%20tool%20-%20translator%20helper%20-%20allinone%20-%2064/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	74	,	minified:"	false	",	title:"	Tool: Page generator	",	img:	img74	,	text:"	Tool for preview possible settings of the site.	",	tag:[	"tool","js","generator"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/74%20-%20code%20-%20js%20-%20tool%20-%20frankenstain%20page%20generator/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	36	,	minified:"	false	",	title:"	Tool: Banner generator	",	img:	img36	,	text:"	Tool prototype for graphics designers. Generates ideas for banners for company Allin1soft.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/36%20-%20code%20-%20js%20-%20tool%20-%20baner%20generator%20-%20allinone%20-%2028/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	73	,	minified:"	false	",	title:"	Tool: Border style splitter	",	img:	img73	,	text:"	Tool for web developers. Modifies border references inside css code.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/73%20-%20code%20-%20js%20-%20tool%20-%20css%20border%20replacer/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	76	,	minified:"	false	",	title:"	Tool: Variable inspector	",	img:	img76	,	text:"	Tool for web developer. Helps modify variables on template code.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/76%20-%20code%20-%20js%20-%20tool%20-%20less%20colors%20key%20generator/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	77	,	minified:"	false	",	title:"	Tool: Sprite Validator	",	img:	img77	,	text:"	Tool for graphic designers. Tests images sprite animation.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/77%20-%20js%20-%20tool%20-%20sprite%20checker/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	6	,	minified:"	false	",	title:"	Tool: Links 	",	img:	img6	,	text:"	Tool for company Allin1soft. Links for the websites on dev , stg and live environment.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/6%20-%20code%20-%20js%20-%20tool%20-%20dev%20stg%20live%20links/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	8	,	minified:"	false	",	title:"	Tool: All tools in one handy page	",	img:	img8	,	text:"	Tool information website for company Allin1soft.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/8%20-%20code%20-%20js%20-%20tool%20-%20all%20in%20one%20-%20allinone/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	72	,	minified:"	false	",	title:"	Tool: Structure helper	",	img:	img72	,	text:"	Tool for web developers. Helps to build structure HTML.	",	tag:[	"tool","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/72%20-%20code%20-%20js%20-%20tool%20-%20generator%20structure%20html/code/	",	source:"		"},	status:	true	,	order:	2	},
  {	id:	100	,	minified:"	true	",	title:"	Module: Slider multi layer	",	img:	img100	,	text:"	Multilayer slider for company Allin1soft.	",	tag:[	"tool","widget","slider","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/100%20-%20code%20-%20js%20-%20slider%20multi%20layer/code/	",	source:"		"},	status:	true	,	order:	4	},
  {	id:	101	,	minified:"	true	",	title:"	Module: Slider one layer	",	img:	img101	,	text:"	Slider with one layer for company Allin1soft.	",	tag:[	"tool","widget","slider","js"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/101%20-%20code%20-%20js%20-%20slider%20one%20layer/code/	",	source:"		"},	status:	true	,	order:	4	},
  {	id:	1	,	minified:"	-	",	title:"	Design: Mobile	",	img:	img1	,	text:"	Mobile website design for company Vegas 7 Games.	",	tag:[	"design","mobile"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/1%20-%20design%20-%20ps%20-%20mobile%20-%20gaminator/mobile_5.jpg	",	source:"		"},	status:	true	,	order:	6	},
  {	id:	2	,	minified:"	-	",	title:"	Design: Website	",	img:	img2	,	text:"	Website design for company Vegas 7 Games.	",	tag:[	"design","site"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/2%20-%20design%20-%20ps%20-%20desktop%20-%20gaminator/gaminator.v68.preview.jpg	",	source:"		"},	status:	true	,	order:	7	},
  {	id:	97	,	minified:"	false	",	title:"	Game: Slot Machine	",	img:	img97	,	text:"	Concept slot machine game. 	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/97%20-%20code%20-%20js%20-%20game%20-%20slot%20machine/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	91	,	minified:"	false	",	title:"	Game: Alchemy + Rpg	",	img:	img91	,	text:"	Concept rpg and alchemy game. 	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/91%20-%20code%20-%20js%20-%20game%20-%20alchemy%20rpg/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	92	,	minified:"	true	",	title:"	Game: Cat Clicker	",	img:	img92	,	text:"	Concept clicker game.	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/92/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	93	,	minified:"	true	",	title:"	Game: Space Arcade	",	img:	img93	,	text:"	Concept arcade game. 	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/93%20-%20code%20-%20js%20-%20game%20-%20floating%20-%20cat/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	94	,	minified:"	true	",	title:"	Game: City Build	",	img:	img94	,	text:"	Concept strategy game. 	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/94%20-%20code%20-%20js%20-%20game%20-%20build%20city/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	95	,	minified:"	true	",	title:"	Game: Puzzle	",	img:	img95	,	text:"	Concept puzzle game. 	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/95%20-%20code%20-%20js%20-%20game%20-%20puzzle/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	96	,	minified:"	true	",	title:"	Game: Build Farm	",	img:	img96	,	text:"	Concept farm game.  	",	tag:[	"playground","js","game"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/96%20-%20code%20-%20js%20-%20game%20-%20build%20city%20isometric/code/	",	source:"		"},	status:	true	,	order:	100	},
  {	id:	103	,	minified:"	-	",	title:"	Design: Logo for PTI	",	img:	img103	,	text:"	Logo design for company PTI.	",	tag:[	"logo","design",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/103%20-%20ps%20-%20logo%20-%20pti/mockup.pti.logo.design.by.maciej.labedzki.jpg	",	source:"		"},	status:	true	,	order:	200	},
  {	id:	102	,	minified:"	-	",	title:"	Design: Logo for Słodka Nuta	",	img:	img102	,	text:"	Logo design for company Słodka Nuta.	",	tag:[	"logo","design",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/102%20-%20ps%20-%20logo%20-%20slodkanuta/8.jpg	",	source:"		"},	status:	true	,	order:	200	},
  {	id:	98	,	minified:"	-	",	title:"	Design: Buisness card for Maciej Łabędzki	",	img:	img98	,	text:"	Buisness card design for Maciej Łabędzki.	",	tag:[	"design",	],	links:	{	preview:"	http://maciejlabedzki.pl/P/98%20-%20ps%20-%20wizytowka%20-%20macie%20labedzki/1.jpg	",	source:"		"},	status:	true	,	order:	200	},
  {	id:	3	,	minified:"	-	",	title:"	Design: California Hemp logo.	",	img:	img3	,	text:"	Package and logo design for company California Hemp. 	",	tag:[	"logo","design","product"	],	links:	{	preview:"	http://maciejlabedzki.pl/P/3%20-%20design%20-%20ps%20-%20product%20-%20hemp/front_bananasmash.6.thc.1.1.jpg	",	source:"		"},	status:	true	,	order:	200	},
  {	id:	111	,	minified:"	-	",	title:"	Website: Cykbudka	",	img:	img111	,	text:"	Website for company Cykbudka. Wordpress CMS.	",	tag:[	"cms" , "wordpress" , "site"	],	links:	{	preview:"	http://cykbudka.pl/	",	source:"		"},	status:	true	,	order:	999	},
  
];
 

export default dataStorage;
