import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

class Owner extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="app-owner">
          <Container>
            <Row>
              <Col sm>
                <span className="icon-user"></span>Maciej Łabędzki
              </Col>
              <Col sm>
                <span className="icon-phone"></span> 530-379-847
              </Col>
              <Col sm>
                <span className="icon-envelop"></span>{" "}
                <a href="mailto:maciej.labedzki@gmail.com">
                  maciej.labedzki@gmail.com
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Owner;
