import React from "react";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";

// PAGE
import Owner from "../components/Owner/Owner";
import NavbarBlock from "../components/Navbar/Navbar";
import CardDeckBlock from "../components/CardDeck/CardDeck";
import JumbotronBlock from "../components/Jumbotron/Jumbotron";
import Technology from "../components/Technology/Technology";
import FooterBlock from "../components/Footer/Footer";

// DATA
import StateStorage from "../../data/state";

// LOGROCKET TESTING
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
LogRocket.init("mdvqsn/maciejlabedzkiplportfolio");
LogRocket.identify("THE_USER_ID_IN_YOUR_APP", {
  name: "Maciej Łabędzki",
  email: "maciejlabedzki@gmail.com",
  subscriptionType: "pro",
});
setupLogRocketReact(LogRocket);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = StateStorage;
  }

  componentWillMount() {}

  componentDidMount() {
    document.body.querySelector("#temp-loader").remove();
  }

  render() {
    return (
      <React.Fragment>
        <section id="modal-owner">
          {this.state.structure.header.owner === true && <Owner />}
        </section>

        <section id="navbar">
          {this.state.structure.header.navbar === true && <NavbarBlock />}
        </section>

        <section id="top">
          {this.state.structure.body.jumbotron === true && <JumbotronBlock />}
        </section>

        <section id="technology">
          {this.state.structure.body.technology === true && <Technology />}
        </section>

        <section id="gallery">
          {this.state.structure.body.cardDeck === true && <CardDeckBlock />}
        </section>

        <section id="footer">
          {this.state.structure.footer.copyright === true && <FooterBlock />}
        </section>
      </React.Fragment>
    );
  }
}

export default App;
