import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

class JumbotronBlock extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Jumbotron className="app-jumbotron">
          <h1 className="jumbotron__title">Front-end Web Developer</h1>
          <p className="jumbotron__desc">
            My name is Maciej Łabędzki. Welcome to my portfolio website.
          </p>
        </Jumbotron>
      </React.Fragment>
    );
  }
}

export default JumbotronBlock;
