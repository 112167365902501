const stateStorage = {
  structure: { 
    header:{
      owner: true,
      navbar: true 
    },
    body:{ 
      jumbotron: true,
      technology: true,
      cardDeck: true
    },
    footer:{
      copyright:true
    },
    search: false,  
  },
  loader:true,
};

export default stateStorage;
