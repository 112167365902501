import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import LazyLoad from "react-lazyload";
import TechnologyList from "./../../../data/storageImgTechnologyImg";

class JumbotronBlock extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Jumbotron className="app-technology">
          <LazyLoad height={500} offset={100}>
            <ul className="technology__list">
              {TechnologyList.map((item) => {
                return (
                  <li
                    key={"id_technology-" + item["name"]}
                    className="zoomIn animated delay-02s"
                  >
                    <img src={item["img"]} alt={item["name"]} />
                  </li>
                );
              })}
            </ul>
          </LazyLoad>
        </Jumbotron>
      </React.Fragment>
    );
  }
}

export default JumbotronBlock;
